<template>
  <section id="section-vision">
    <div class="section-vision__wrap">
      <div class="section__head section-vision__head">
        <h3>VISION</h3>
      </div>
      <div class="section-vision__subhead">
        <p class="section-vision__subhead-text">
          Your Change Your Power
        </p>
        <p class="section-vision__subhead-text">
          体を動かし、体の変化を感じる。それがみなぎるパワーの源。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* section-vision */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */

#section-vision {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: cover;
  position: relative;
  background-image: url("../../assets/lp_new/section_vision_back_item.png");
  background-repeat: no-repeat;
  background-position: right;
  background-color: #272727;
  height: 580px;
}

.section-vision__wrap {
  width: 100%;
  max-width: 1020px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section-vision__subhead {
  margin-top: 15px;
}

.section-vision__subhead p {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

@media screen and (max-width: 1020px) {
  #section-vision {
    height: 450px;
    background-size: cover;
  }

  .section-vision__subhead p {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  #section-vision {
    height: 310px;
  }

  .section-vision__subhead {
    margin: 15px 15px 0 15px;
  }

  .section-vision__subhead p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
}
</style>
